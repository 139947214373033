import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { gsap } from 'gsap';
import { insertWinnerContact } from '../services/database';
import localforage from 'localforage'; // Assuming we're storing gamepin in localforage

const correctPin = '1397';
const maxTries = 3;

const PinEntry = () => {
  const [pin, setPin] = useState('');
  const [tries, setTries] = useState(0);
  const [gamepin, setGamepin] = useState(''); // New state for gamepin
  const location = useLocation();
  const navigate = useNavigate();
  const contactInfo = location.state ? location.state.contactInfo : {};

  useEffect(() => {
    // Fetch gamepin from local storage or other settings
    const fetchGamepin = async () => {
      const storedGamepin = await localforage.getItem('gamePin') || '0000';
      setGamepin(storedGamepin);
    };

    fetchGamepin();

    // Fade in animation on page load
    gsap.fromTo(".page-container", 
      { opacity: 0 }, 
      { opacity: 1, duration: 0.5 }
    );
  }, []);

  const handleButtonClick = (value) => {
    if (pin.length < 6) {
      setPin(pin + value);
    }
  };

  const handleClear = () => {
    setPin('');
  };

  const handleSubmit = async () => {
    if (pin === correctPin || pin === gamepin) {
      try {
        console.log(contactInfo);
        await insertWinnerContact(contactInfo);
        // Fade out animation and then navigate
        gsap.to(".page-container", {
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            navigate('/success', { state: { contactInfo } });
          }
        });
      } catch (error) {
        console.error('Error saving contact information:', error);
      }
    } else {
      setPin('');
      setTries(tries + 1);
      if (tries >= maxTries) {
        navigate('/');
      }
    }
  };

  const renderDots = () => {
    return [0, 1, 2, 3, 4, 5].map((index) => (
      <div key={index} className="pin-slot">
        <span className="underscore"></span>
        {pin.length > index && <span className="dot"></span>}
      </div>
    ));
  };

  return (
    <div className="page-container">
      <div className='page-header'>
        <h1 className="page-title">Enter PIN</h1>
        <div>Winner verification</div>
        <div>Promoter to enter code</div>
      </div>
      <div className='page-content'>
        <div className="pin-display">{renderDots()}</div>
        <div className="numpad">
          {['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((num) => (
            <button key={num} onClick={() => handleButtonClick(num)}>{num}</button>
          ))}
          <button className="zero-button" onClick={() => handleButtonClick('0')}>0</button>
        </div>
      </div>
      <div className='page-footer'>
        <button className="submit-button page-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default PinEntry;
